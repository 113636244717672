import React from 'react'

export default function ButtonLinkExternal({
  text,
  url,
  color,
  inverse_color,
}) {
  const classes = `text-${color} hover:text-${inverse_color} border-${color} hover:bg-${color} p-3 text-lg no-underline font-bold border-2 transition-bg`
  return (
    <a href={url} className={classes} target="_blank" rel="noopener">
      {text}
    </a>
  )
}
