import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import SectionContact from '../components/section-contact'
import ButtonLinkExternal from '../components/button-link-external'

export default function TemplateWork({ data, location }) {
  const { wordpressWpClient } = data
  const { acf } = wordpressWpClient
  const { work_info, contact_section, seo, open_graph, twitter } = acf
  return (
    <Layout location={location}>
      <Helmet
        title={seo.title}
        meta={[
          { name: 'description', content: seo.description },
          { name: 'og:title', content: open_graph.title },
          { name: 'og:description', content: open_graph.description },
          { name: 'og:image', content: open_graph.image.source_url },
          {
            name: 'og:url',
            content: `https://www.modinfinity.com/work/${
              wordpressWpClient.slug
            }`,
          },
          { name: 'twitter:title', content: twitter.title },
          { name: 'twitter:description', content: twitter.description },
          { name: 'twitter:image', content: twitter.image.source_url },
          { name: 'twitter:card', content: twitter.card_size },
        ]}
      />
      <section className="container mx-auto xl:px-6 py-8 lg:py-16 xl:py-32 xl:flex xl:flex-row-reverse xl:items-center">
        <div className="xl:w-3/5 xl:shadow-lg">
          <Img
            fluid={work_info.main_image.localFile.childImageSharp.fluid}
            alt={work_info.main_image.alt_text}
          />
        </div>
        <div className="xl:w-2/5 xl:px-4">
          <h1 className="pt-10 font-black text-4xl md:text-5xl lg:text-6xl xxl:text-7xl text-indigo-darkest text-center xl:text-left">
            {work_info.title}
          </h1>
          <div className="py-6 flex justify-center xl:justify-start items-center">
            <ButtonLinkExternal
              url={work_info.link}
              text="View website"
              color="indigo-darkest"
              inverse_color="indigo-lightest"
            />
          </div>
        </div>
      </section>
      <section className="container mx-auto px-2">
        <div
          className="work-content md:w-3/4 lg:w-3/5 xl:w-1/2 md:mx-auto"
          dangerouslySetInnerHTML={{ __html: work_info.copy }}
        />
      </section>
      <SectionContact contact={contact_section} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpClient(slug: { eq: $slug }) {
      id
      slug
      acf {
        work_info {
          title
          copy
          link
          main_image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        contact_section {
          title
          call_to_action
          illustration
          illustration_position
        }
        seo {
          title
          description
        }
        open_graph {
          title
          description
          image {
            source_url
          }
        }
        twitter {
          title
          description
          card_size
          image {
            source_url
          }
        }
      }
    }
  }
`
